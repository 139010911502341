import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'

import ScrollLock from 'react-scrolllock'

import '../scss/layout.scss'

import logo from '../assets/images/logo.svg'
import facebooklogo from '../assets/images/facebooklogo.svg'
import linkedinlogo from '../assets/images/linkedinlogo.png'

export default function Layout({children}) {
  const [openMobileNav, setOpenMobileNav] = useState(false)

  return (
    <div className="layout">
      <Helmet
        title="DigiWells"
        meta={[
          { name: '', content: '' },
          { name: '', content: '' },
        ]}
      >
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@600;700&display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600&family=Poppins:wght@600;700&display=swap" rel="stylesheet" />
      </Helmet>

      <div className="container">
        <div className="navigation">
          <div className="navigation-brand">
            <Link to={process.env.BASE_URL}>
              <img src={logo} alt="Logo" />
            </Link>
          </div>
          <div className="navigation-mobile-toggle" onClick={() => { setOpenMobileNav(!openMobileNav) }}>
            {openMobileNav ? 'Close menu' : 'Menu'}
          </div>
          <div className={`navigation-nav ${openMobileNav ? 'open' : ''}`}>
            <ul>
              <li>
                <Link to="/results/" activeClassName="active" partiallyActive={true}>Results</Link>
              </li>
              <li>
                <Link to="/events/" activeClassName="active" partiallyActive={true}>Events</Link>
              </li>
              <li>
                <Link to="/news/" activeClassName="active" partiallyActive={true}>News</Link>
              </li>
              <li>
                <Link to="/reports/" activeClassName="active" partiallyActive={true}>Reports</Link>
              </li>
              <li>
                <Link to="/about/" activeClassName="active" partiallyActive={true}>About</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <ScrollLock isActive={openMobileNav}>
        <div>{children}</div>
      </ScrollLock>

      <footer className="footer">
        <div className="container container-10">
          <div className="title">
            DigiWells
          </div>
          <div className="grid">
            <div className="grid-item">
              <p>
                <strong>Post address</strong>
                Postboks 22 Nygårdstangen, 5838 Bergen
              </p>
              <p>
                <strong>E-mail</strong>
                mette.stokseth-myhre@norceresearch.no
              </p>
              <p>
                <strong>Phone</strong>
                56 10 70 00
              </p>
              <p>
                <strong>Visiting address</strong>
                See an overview of&nbsp;
                <a href="https://www.norceresearch.no/en/about-us/lokasjoner">our locations</a>
              </p>
            </div>
            <div className="grid-item">
              <p>
                <strong>Connect follow and talk with us:</strong>
              </p>
              <p>
                <a className="link" href="https://www.linkedin.com/company/norce/">
                  <img className="logo" alt="" src={linkedinlogo}/>
                  LinkedIn
                </a>
                <a className="link" href="https://www.facebook.com/norceresearch/">
                  <img className="logo" alt="" src={facebooklogo}/>
                  Facebook
                </a>
              </p>
              <p>
                <a href="https://uni.us5.list-manage.com/subscribe?u=ee0f25f3f3&id=19def91d4e"> Join our newsletter </a>
              </p>
            </div>
          </div>

        </div>

      </footer>
    </div>
  )
}
